import React, { useState, useEffect } from "react";
import "./blogPerfil.css";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";
import SimpleModal2 from "../../../../blog/ModalProjeto";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import parse from 'html-react-parser';
import Portas from "../../../../../portas";
import Ods from "./components/Ods";
import verifyField from "../../../../../components/funcoes/verifyField";

//icons
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Icone1 } from "../../../../../components/icons/cadastro/bolsistas.svg";
import { ReactComponent as Icone2 } from "../../../../../components/icons/cadastro/projetos.svg";
import { ReactComponent as Icone3 } from "../../../../../components/icons/cadastro/editais.svg";
import { ReactComponent as Icone4 } from "../../../../../components/icons/cadastro/empresasFomentadas.svg";
import { ReactComponent as Icone5 } from "../../../../../components/icons/cadastro/projetos.svg";
import { ReactComponent as Icone6 } from "../../../../../components/icons/cadastro/setoresAtingidos.svg";
import { ReactComponent as Icone1_ } from "../../../../../components/icons/cadastro/bolsistas_.svg";
import { ReactComponent as Icone2_ } from "../../../../../components/icons/cadastro/projetos_.svg";
import { ReactComponent as Icone3_ } from "../../../../../components/icons/cadastro/editais_.svg";
import { ReactComponent as Icone4_ } from "../../../../../components/icons/cadastro/empresasFomentadas_.svg";
import { ReactComponent as Icone5_ } from "../../../../../components/icons/cadastro/projetos_.svg";
import { ReactComponent as Icone6_ } from "../../../../../components/icons/cadastro/setoresAtingidos_.svg";

import { ReactComponent as Instagram } from "./icons/instagram.svg";
import { ReactComponent as Facebook } from "./icons/facebook.svg";
import { ReactComponent as Youtube } from "./icons/youtube.svg";
import { ReactComponent as CNPq } from "./icons/cnpq.svg";
import { ReactComponent as Linkedin } from "./icons/linkedin.svg";

import { ReactComponent as Agricultura } from '../../../../../components/icons/areasCientistaChefe/agricultura.svg';
import { ReactComponent as Aquicultura } from '../../../../../components/icons/areasCientistaChefe/aquicult.svg';
import { ReactComponent as Ciencia } from '../../../../../components/icons/areasCientistaChefe/ciencia.svg';
import { ReactComponent as Contas } from '../../../../../components/icons/areasCientistaChefe/contas.svg';
import { ReactComponent as Cultura } from '../../../../../components/icons/areasCientistaChefe/cultura.svg';
import { ReactComponent as Dados } from '../../../../../components/icons/areasCientistaChefe/dados.svg';
import { ReactComponent as Digital } from '../../../../../components/icons/areasCientistaChefe/digital.svg';
import { ReactComponent as Economia } from '../../../../../components/icons/areasCientistaChefe/economia.svg';
import { ReactComponent as Educacao } from '../../../../../components/icons/areasCientistaChefe/Educ.svg';
import { ReactComponent as Energia } from '../../../../../components/icons/areasCientistaChefe/energia.svg';
import { ReactComponent as Hid } from '../../../../../components/icons/areasCientistaChefe/hid.svg';
import { ReactComponent as Infraestrutura } from '../../../../../components/icons/areasCientistaChefe/infraestrutura.svg';
import { ReactComponent as Inovacao } from '../../../../../components/icons/areasCientistaChefe/inovacao.svg';
import { ReactComponent as Justica } from '../../../../../components/icons/areasCientistaChefe/justica.svg';
import { ReactComponent as MeioAmbiente } from '../../../../../components/icons/areasCientistaChefe/meioambiente.svg';
import { ReactComponent as Protecao } from '../../../../../components/icons/areasCientistaChefe/Prot.svg';
import { ReactComponent as Pesca } from '../../../../../components/icons/areasCientistaChefe/pesca.svg';
import { ReactComponent as Saude } from '../../../../../components/icons/areasCientistaChefe/saude.svg';
import { ReactComponent as Seguranca } from '../../../../../components/icons/areasCientistaChefe/seguranca.svg';
import { ReactComponent as Financaspf } from '../../../../../components/icons/areasCientistaChefe/financaspf.svg';
import { ReactComponent as BigDatapf } from '../../../../../components/icons/areasCientistaChefe/bigdatapf.svg';
import { ReactComponent as Pecuaria } from '../../../../../components/icons/areasCientistaChefe/pecuaria.svg';
import { ReactComponent as Costa } from '../../../../../components/icons/areasCientistaChefe/costa.svg';
import { ReactComponent as Fiscal } from '../../../../../components/icons/areasCientistaChefe/fiscal.svg';
import { ReactComponent as Trabalho } from '../../../../../components/icons/areasCientistaChefe/trabalho.svg';

const getIcone = (index) => {
  if (index === 0) {
    return <Icone1 />;
  } else if (index === 1) {
    return <Icone2 />;
  } else if (index === 2) {
    return <Icone3 />;
  } else if (index === 3) {
    return <Icone4 />;
  } else if (index === 4) {
    return <Icone5 />;
  } else if (index === 5) {
    return <Icone6 />;
  }
};

const getIcone1 = (index) => {
  if (index === 0) {
    return <Icone1_ />;
  } else if (index === 1) {
    return <Icone2_ />;
  } else if (index === 2) {
    return <Icone3_ />;
  } else if (index === 3) {
    return <Icone4_ />;
  } else if (index === 4) {
    return <Icone5_ />;
  } else if (index === 5) {
    return <Icone6_ />;
  }
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#FDCC09",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },

  btnVoltar2: {
    backgroundColor: "#A9A9A9",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));



export default function App({ setOpen, selectedCientista }) {
  const classes = useStyles();
  const [open3, setOpen3] = useState(false);
  const [perfil, setPerfil] = useState("");
  const [projetos, setProjetos] = useState("");
  const [selectedProjeto, setSelectedProjeto] = useState();
  const [areasInativas, setAreasInativas] = useState(["Saúde","Ciência de Dados","Segurança Pública","Tribunal de Contas"]);

  const openInNewTab = url => {
    window.open(url, '_blank');
  };

  function handleClickProjeto(id) {
    setSelectedProjeto(id);
    setOpen3(true);

  }

  function getArea(area) {
    //console.log("entrei")
    console.log(area)
    if (area === "Big Data") {
      return (<BigDatapf />)
    }
    if (area === "Finanças") {
      return (<Financaspf />)
    }
    if (area === "Tribunal de Contas") {
      return (<Contas />)
    }
    if (area === "Transformação Digital") {
      return (<Digital />)
    }
    if (area === "Segurança Pública") {
      return (<Seguranca />)
    }
    if (area === "Saúde") {
      return (<Saude />)
    }
    if (area === "Recursos Hídricos") {
      return (<Hid />)
    }
    if (area === "Proteção Social") {
      return (<Protecao />)
    }
    if (area === "Pesca") {
      return (<Pesca />)
    }
    if (area === "Meio Ambiente") {
      return (<MeioAmbiente />)
    }
    if (area === "Justiça") {
      return (<Justica />)
    }
    if (area === "Inovação") {
      return (<Inovacao />)
    }
    if (area === "Infraestrutura") {
      return (<Infraestrutura />)
    }
    if (area === "Energia") {
      return (<Energia />)
    }
    if (area === "Educação") {
      return (<Educacao />)
    }
    if (area === "Economia") {
      return (<Economia />)
    }
    if (area === "Cultura") {
      return (<Cultura />)
    }
    if (area === "Infraestrutura Costeira") {
      return (<Costa />)
    }
    if (area === "Avaliação de Ações de Ciência e Inovação") {
      return (<Ciencia />)
    }
    if (area === "Ciência de Dados") {
      return (<Dados />)
    }
    if (area === "Economia do Mar") {
      return (<Aquicultura />)
    }
    if (area === "Agricultura") {
      return (<Agricultura />)
    }
    if (area === "Pecuária") {
      return (<Pecuaria />)
    }
    if (area === "Trabalho") {
      return (<Trabalho />)
    }
    if (area === "Política Fiscal") {
        return (<Fiscal />)
    }

    return (<></>)

  }

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }

  const getInfo = async () => {
    try {
      const response1 = await fetch(Portas().serverHost + "/usuarios/cientista/" + selectedCientista.cientista.id + "?cientista=true&acesso=true&image=true",
        {
          method: "GET",
        }
      );

      const resJSON1 = await response1.json();

      setPerfil(resJSON1)
      console.log("perfil")
      console.log(resJSON1)


      const response = await fetch(Portas().serverHost + "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON = await response.json();


      var auxproj = []
      for (var i = 0; i < resJSON.length; i++) {

        if (resJSON[i].area_projeto.area === selectedCientista.area /*&& resJSON[i].id_autor === resJSON1.id*/) {
          auxproj.push(resJSON[i])
        }
      }
      setProjetos(auxproj)

    }
    catch (err) {

    }
  }

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div className="blogPerfil">
      {open3 ? (
        <SimpleModal2
          open={open3}
          id={selectedProjeto}
          setOpen={() => setOpen3(!open3)}
        ></SimpleModal2>
      ) : (
        <></>
      )}
      <div className="blogPerfilCientista">
        <div className="blogCabecalhoPerfil">
          <div className="divVoltarCompartilharCientistaChefe">
            <Button
              className={areasInativas.includes(selectedCientista.area) ? classes.btnVoltar2 : classes.btnVoltar}
              variant="contained"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setOpen(false)}
            >
              Voltar
            </Button>
            <Breadcrumbs>
              <div
                className="blogCabecalhoTextPerfil"
                underline="hover"
                color="inherit"
                href="https://localhost:3000/blogPerfil"
              >
                {("Cientista chefe: " + selectedCientista.area).toUpperCase()}
              </div>
            </Breadcrumbs>

          </div>
        </div>

        <div className="blogPerfilTitulo">
          <div className={areasInativas.includes(selectedCientista.area) ? "divIconeCientista2" : "divIconeCientista1"}>
            <SvgIcon style={{ fontSize: "50px", color: "#FFFFFF" }}>
              {getArea(selectedCientista.area)}
            </SvgIcon>

          </div>
          <div className={areasInativas.includes(selectedCientista.area) ? "divNomeCientista2" : "divNomeCientista1"}>
            <h1>{("Cientista chefe: " + selectedCientista.area).toUpperCase()}</h1>{" "}
          </div>
        </div>

        <div className="conteudoPerfil">
          <div>
            {perfil !== "" ?
              <div
                className="fotoperfil"
                style={{
                  backgroundImage: "url('" + perfil.foto + "')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >

                <div className="divIconsFotos">
                  {verifyField(perfil.cientista.lattes) ?
                    <div className="iconFoto" style={{ marginRight: "8px" }} onClick={() => openInNewTab(perfil.cientista.lattes)}>
                      <SvgIcon
                        style={{
                          fontSize: "32px",
                          color: "inherit",
                          backgroundColor: "inherit",
                        }}
                      >
                        <CNPq />
                      </SvgIcon>
                    </div>
                    : <></>}
                  {verifyField(perfil.cientista.linkedin) ?
                    <div className="iconFoto" onClick={() => openInNewTab(perfil.cientista.linkedin)}>
                      <SvgIcon
                        style={{
                          fontSize: "32px",
                          color: "inherit",
                          backgroundColor: "inherit",
                        }}
                      >
                        <Linkedin />
                      </SvgIcon>
                    </div>
                    : <></>}
                </div>
              </div>
              : <></>}
          </div>


          <div>
            {perfil !== "" ?
              <div className="pesquisadorResponsavel">
                {"Pesquisador Responsável - " + perfil.nome}
              </div>
              : <></>}

            {perfil !== "" ?
              <p style={{ fontSize: "14px", textAlign: "justify" }}>
                {parse(perfil.cientista.descricao)}
              </p>
              : <></>
            }
          </div>

        </div>
        <div>
          {perfil !== "" ?
            <div style={{ marginBottom: "32px" }}>
              <h2 className="maisInformacao"> Projetos: </h2>
              <div style={{ fontSize: "14px", textAlign: "justify" }}>
                {perfil.cientista.projetos.map((projeto, index) =>
                  <div>
                    {"• " + projeto}
                  </div>
                )}
              </div>
            </div>
            : <></>}
        </div>
        {perfil !== "" ?
          <div>
            <h2 className="maisInformacao">
              Orgão Beneficiado:
            </h2>
            <div>
              {"• " + perfil.cientista.orgao}
            </div>
          </div>
          : <></>}
        {perfil !== "" ?
          <div style={{ marginTop: "32px", marginBottom: "32px" }}>
            <h2 className="maisInformacao">
              {" "}
              Programas do Plano Plurianual (PPA) beneficiados{" "}
            </h2>
            {perfil.cientista.ppa.map((item, index) =>
              <div>
                {"• " + item.titulo}
              </div>
            )}
          </div>
          : <></>}
        {perfil !== "" ?
          <div>
            <h2 className="maisInformacao">
              {" "}
              Objetivos de Desenvolvimento Sustentável (ODS) Atingidos:{" "}
            </h2>

            <Ods ods={perfil.cientista.objetivos} />
          </div>
          : <></>
        }
        {perfil !== "" ?
          <div>
            {verifyField(perfil.cientista.instagram) || verifyField(perfil.cientista.facebook) || verifyField(perfil.cientista.youtube)
              ?
              <h2 className="maisInformacao" style={{ marginTop: "32px" }}>
                Mídias Sociais do Programa:{" "}
              </h2>
              : <></>}
            {verifyField(perfil.cientista.instagram) ?
              <div className="midiasSociaisInsta" style={{ marginLeft: "8px" }} onClick={() => openInNewTab(perfil.cientista.instagram)}>
                <SvgIcon style={{
                  fontSize: "24px", color: "inherit",
                  backgroundColor: "inherit"
                }}>
                  <Instagram />
                </SvgIcon>
                <div style={{ marginLeft: "5px" }}>Instagram</div>
              </div>
              : <></>}
            {verifyField(perfil.cientista.facebook) ?
              <div className="midiasSociaisFacebook" style={{ marginLeft: "8px" }} onClick={() => openInNewTab(perfil.cientista.facebook)}>
                <SvgIcon style={{
                  fontSize: "24px", color: "inherit",
                  backgroundColor: "inherit"
                }}>
                  <Facebook />
                </SvgIcon>
                <div style={{ marginLeft: "5px" }}>Facebook</div>
              </div>
              : <></>}
            {verifyField(perfil.cientista.youtube) ?
              <div className="midiasSociaisYoutube" style={{ marginLeft: "8px" }} onClick={() => openInNewTab(perfil.cientista.youtube)}>
                <SvgIcon style={{
                  fontSize: "24px", color: "inherit",
                  backgroundColor: "inherit"
                }}>
                  <Youtube />
                </SvgIcon>
                <div style={{ marginLeft: "5px" }}>Youtube</div>
              </div>
              : <></>
            }
          </div>
          : <></>}

        <div className="destaquesAreas">
          <h2 className="maisInformacaoDestaqueArea"> Destaques da área </h2>
          {perfil !== "" ?
            <div className="divCentralCientista">
              {perfil.cientista.indicadores.map((item, index) =>
                <div className="divIconeCientista">
                  {areasInativas.includes(selectedCientista.area) ?
                    <SvgIcon style={{ fontSize: "50px", color: "#A9A9A9" }}>
                      {getIcone1(item.icone)}
                    </SvgIcon>
                    :
                    <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                      {getIcone(item.icone)}
                    </SvgIcon>
                  }
                  <p className="iconeTituloBlogCientista">
                    <b className="iconeNumeroBlogCientista">{item.valor}</b>
                    <br></br>{item.indicador}<br></br>
                  </p>
                </div>
              )}
            </div>
            : <></>}
        </div>
      </div>
      {projetos.length > 0 ?
        <div className="blogsInovacaoCientista">
          <div style={{ width: "100%" }}>
            <p className="projetosRelacionados">PROJETOS E REALIZAÇÕES</p>
          </div>
        </div>
        : <></>
      }
      {projetos.length > 0 ?
        <div className="blogsProjetoCientista" style={{ marginTop: "0px" }}>
          <div style={{ width: "100%" }}>
            <div className="linearGradBloCientista"></div>
          </div>
        </div>
        : <></>}
      {projetos.length > 0 ?
        <div className="blogListCienciaProjeto">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {projetos.map((item) => (
                <div style={{ display: "flex" }}>
                  <div className="divContentBlogCienProjeto">
                    <img
                      className="imgBlogCienProjeto"
                      alt=""
                      src={item.capa}
                    />
                    <div className="divTextBottomImgBlogTipo1CienProjeto">
                      <div className="textBottomImgBlogTipo1CienProjeto">PROJETO</div>
                    </div>
                  </div>
                  <div className="divTextBlogCienProjeto">
                    <div className="divTextTituloBlogTipo1CienProjeto">
                      <div>
                        {item.titulo}
                      </div>
                    </div>
                    <div className="divTextDataBlogTipo1CienProjeto">
                      <div>Postado em {getData(item.createdAt, "dia")}/{getData(item.createdAt, "mes")}/{getData(item.createdAt, "ano")}</div>
                    </div>
                    <div className="divTextResumoBlogCienProjeto">
                      <div>
                        {parse(item.conteudo)}
                      </div>
                    </div>
                    <div className="divButtonLerMaisCienProjeto">
                      <button
                        className="buttonLerMaisCienProjeto"
                        onClick={() => handleClickProjeto(item.id)}
                      >
                        Ler Mais
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            : <></>

          </div>
        </div>
        : <></>}
    </div >

  );
}
